@import "../assets/scss/defaultTheme.scss";

.header {
    width: 100%;
    position: fixed;
    z-index: 999;
}
.outer {
    position: relative;
    height: 100px;
    top: 60px;
}
.default_page_close{
   // margin-top: 15px;
   // width: 88%;
   // float: right;
    margin-right: 20px;
    margin-left: 220px;

}
.default_page_open{
    //width: 98%;
  //  float: right;
   // margin-top: 15px;
    margin-right: 20px;
    margin-left: 20px;
   
}

