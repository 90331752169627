$primaryColor: #51ac37;
$secondaryColor: #5c5c5c;
$warningColor: #f4901f;
$errorColor: #bd2130;

@font-face {
    font-family: "Foco";
    src: url("../fonts/Foco-Regular.ttf");
}
@font-face {
    font-family: "Foco-Bold";
    src: url("../fonts/Foco-Bold.ttf");
}

*{
    margin:0;
    padding:0;
    text-decoration: none;
}


body {
    font-size: 12px;
    font-family: "Foco";
}

/*-- Default font size --*/
html {
    font-size: 16px;
}

@media (max-width: 320px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 321px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 375px) {
    html {
        font-size: 11px;
    }
}
@media (min-width: 768px) {
    html {
        font-size: 11px;
    }
}

@media (min-width: 1200px) {
    html {
        font-size: 14px;
    }
}

// @media (min-width: 1400px) {
//     html {
//         font-size: 14px;
//     }
// }

@media (min-width: 2000px) {
    html {
        font-size: 20px;
    }
}
.btn {
  padding: 6px!important;
  font-size: 12px!important;
}
.btn-block {
  display: block;
  width: 100%;
}
.card-header {
    font-size: 13px;
    font-weight: 450;
    line-height: 8px;
    letter-spacing: .02em;
    color: #fff;
    // background-color: #60bb46 !important;
    background-color: #344154 !important;
    padding: 8px 20px !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
}